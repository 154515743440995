import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog21/image1.jpg"
import image2 from "../../images/blog21/image2.jpg"
import image3 from "../../images/blog21/image3.jpg"
import image4 from "../../images/blog21/image4.jpg"
import image5 from "../../images/blog21/image5.jpg"
import image6 from "../../images/blog21/image6.jpg"
import image7 from "../../images/blog21/image7.jpg"
import FoodsToAvoidSwiper from "../../components/foods-to-avoid-swiper";

export default function FoodsToAvoidWhenInPain(){
    return(
        <BlogPostOld
            src={image6}
            title={"Foods to Avoid When in Pain"}
            summary={"We often forget all of the factors that can influence pain. The easiest thing to do is to blame the last activity we have done physically. This leads to creating a bunch of physical limitations, causing inactivity. Doing this long enough leads your body to become less prepared for the physical activities you enjoy; it’s a vicious cycle. I see this all of the time. "}
            date={"August 29, 2022"}
            author={"Dr. Donald Mull, DC"}
            NoImage={true}
        >
            <div>
                <p>We often forget all of the factors that can influence pain. The easiest thing to do is to blame the last activity we have done physically. This leads to creating a bunch of physical limitations, causing inactivity. Doing this long enough leads your body to become less prepared for the physical activities you enjoy; it’s a vicious cycle. I see this all of the time.&nbsp;</p>
                <p>Physical stressors/activities are only one of many influencers of pain. Another influencer is food. Yes, food absolutely can cause an increase in the pain you feel. On the other hand, it can have a POSITIVE effect on pain.&nbsp;</p>
                <p>Take a look at these phenomenal slides from Adam Meakans, a Physio from the UK, that can help guide you when either recovering from an acute injury or even when experiencing chronic pain. It is literally like taking ibuprofen without having to take a pill.&nbsp;</p>
                <FoodsToAvoidSwiper/>
                <p>The big take-away here, especially for those experiencing long lasting pain is to realize how many different things can influence pain. In most cases, there is not one thing to “fix”. There are many lifestyle changes like sleep, physical activity, stress management, foods you eat, social support, even the outlook on your recovery can be addressed that will have a positive influence on the pain you are feeling.</p>
                <p>You have so many things you can leverage at your disposal. I think it is the job of the clinician to work WITH you to create a reasonable plan that can work for you. This process can take some time and there should be a collaborative effort to make any adjustments needed along the way.&nbsp;</p>
                <p>This is why we are in your corner and are here for you, no questions asked. THAT to us is true healthcare. If you haven’t worked with us and want to learn how you can live without limitations, <a href="https://kineticimpact.janeapp.com/" target="_blank" rel="noopener">schedule an appointment here</a> or shoot us a <a href="mailto:info@kirehab.com" target="_blank" rel="noopener">message</a>. We are here for you!</p>
            </div>
        </BlogPostOld>

    )
}