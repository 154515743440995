import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

import image from "../images/blog21/image1.jpg"
import image2 from "../images/blog21/image2.jpg"
import image3 from "../images/blog21/image3.jpg"
import image4 from "../images/blog21/image4.jpg"
import image5 from "../images/blog21/image5.jpg"
import image6 from "../images/blog21/image6.jpg"
import image7 from "../images/blog21/image7.jpg"

export default function FoodsToAvoidSwiper() {
    return (
        <>
            <Swiper
                pagination={{
                    type: "fraction",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <img className={"w-full"} src={image6} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image4} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image2} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image7} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image3} alt={"slideshow"}/>
                </SwiperSlide>
                <SwiperSlide>
                    <img className={"w-full"} src={image5} alt={"slideshow"}/>
                </SwiperSlide>










            </Swiper>
        </>
    );
}
